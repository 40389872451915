import React from "react";
import { Link } from "react-router-dom";
import pdf from "../Brochure/ph-steels.pdf";
// import ShareBtn from "./SocialShareBtn";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function Footer() {
  return (
    <div>
      <footer className="footer-20192">
        <div className="site-section">
          <div className="container text-center">
            <div className="cta d-block d-md-flex d-sm-flex align-items-center px-5 d-flex justify-content-around">
              <div>
                <h2
                  className="mb-0"
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-once="true"
                >
                  Send your Enquiry
                </h2>
              </div>
              <div className="mx-3">
                <Link
                  to="/contact"
                  className="btn btn-outline-dark rounded-3 py-2 px-3 footer-enquire-btn"
                  onClick={scrollToTop}
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-sm mt-2">
                <Link to="/" className="footer-logo" onClick={scrollToTop}>
                  PH Steels
                </Link>
                <p className="copyright">
                  <small>
                    &copy; {new Date().getFullYear()} All Rights Reserved
                  </small>
                </p>
                <div className="row">
                  <div className="mt-2">
                    <ul className="list-unstyled social">
                      <li>
                        <Link
                          id="footer-social-instagram"
                          to="/"
                          data-aos="fade-up"
                          data-aos-delay="200"
                          data-aos-once="true"
                        >
                          <span className="fa-brands fa-instagram"></span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          id="footer-social-x"
                          to="/"
                          data-aos="fade-up"
                          data-aos-delay="300"
                          data-aos-once="true"
                        >
                          <span className="fa-brands fa-x-twitter"></span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          id="footer-social-linkedin"
                          to="/"
                          data-aos="fade-up"
                          data-aos-delay="400"
                          data-aos-once="true"
                        >
                          <span className="fa-brands fa-linkedin"></span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          id="footer-social-facebook"
                          to="/"
                          data-aos="fade-up"
                          data-aos-delay="500"
                          data-aos-once="true"
                        >
                          <span className="fa-brands fa-facebook"></span>
                        </Link>
                      </li>
                      {/* <br />
                      <br />
                      <li>
                        <ShareBtn />
                        <br />
                        Share Us
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm mt-2">
                <h3
                  id="footer-links-header"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  Company Overview
                </h3>
                <ul className="list-unstyled links">
                  <li>
                    <Link
                      className="hover-underline-animation-footer"
                      to="/"
                      onClick={scrollToTop}
                      data-aos="fade-up"
                      data-aos-delay="250"
                      data-aos-once="true"
                    >
                      <i className="fa-solid fa-home"></i> Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover-underline-animation-footer"
                      to="/about"
                      onClick={scrollToTop}
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-once="true"
                    >
                      <i className="fa-solid fa-user"></i> About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover-underline-animation-footer"
                      to="/contact"
                      onClick={scrollToTop}
                      data-aos="fade-up"
                      data-aos-delay="350"
                      data-aos-once="true"
                    >
                      <i className="fa-solid fa-phone"></i> Contact us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover-underline-animation-footer"
                      to="/products"
                      onClick={scrollToTop}
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-once="true"
                    >
                      <i className="fa-solid fa-list"></i> Our Products
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm mt-2">
                <h3
                  id="footer-links-header"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  Further Information
                </h3>
                <ul className="list-unstyled links">
                  <li>
                    <Link
                      className="hover-underline-animation-footer"
                      to="/corporate-video"
                      onClick={scrollToTop}
                      data-aos="fade-up"
                      data-aos-delay="350"
                      data-aos-once="true"
                    >
                      <i className="fa-solid fa-video"></i> Corporate Video
                    </Link>
                  </li>
                  <li>
                    <a
                      className="hover-underline-animation-footer"
                      to="/"
                      href={pdf}
                      download="ph-steels.pdf"
                      target="_blank"
                      rel="noreferrer"
                      data-aos="fade-up"
                      data-aos-delay="350"
                      data-aos-once="true"
                    >
                      <i className="fa-solid fa-arrow-down"></i> Download
                      Brochure
                    </a>
                  </li>
                  <li>
                    <Link
                      className="hover-underline-animation-footer"
                      to="/terms-and-conditions"
                      onClick={scrollToTop}
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-once="true"
                    >
                      <i className="fa-solid fa-book"></i> Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm mt-2">
                <h3
                  id="footer-links-header"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  Company Address
                </h3>
                <p className="company-address">
                  PH Steels <br /> 102, Loha Mandi, Bulandshahr Road Industrial
                  Area, <br /> Ghaziabad - 201009, Uttar Pradesh, India
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
