import { React, useState, useEffect } from "react";
import Loading from "../../../spinner";
import { Link } from "react-router-dom";
import img1 from "../Images/ColorCoated/color-coated-coil-1.jpg";
import img2 from "../Images/ColorCoated/color-coated-coil-2.jpg";
import img3 from "../Images/ColorCoated/color-coated-coil-3.jpg";
import img4 from "../Images/ColorCoated/color-coated-coil-4.jpg";
import img5 from "../Images/ColorCoated/color-coated-coil-5.jpg";
import img6 from "../Images/ColorCoated/color-coated-coil-1.jpg";
import img7 from "../Images/ColorCoated/color-coated-coil-2.jpg";

export default function ColorCoatedCoil() {
  // const handleLinkClick = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 550);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="spinner">
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="container-fluid mb-5 pb-5">
          <div className="row">
            <div className="col-md-6">
              <Link to="/products">
                <p className="hover-underline-animation">
                  <i className="fa-solid fa-arrow-left"></i> Back to Products
                </p>
              </Link>
              <section id="marquee__container">
                <h2 className="marquee__title">Color Coated Coil</h2>

                <div className="marquee">
                  <ul className="marquee__content">
                    <li>
                      <img
                        className="marquee__img"
                        src={img1}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img2}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img3}
                        alt="Brand_Image"
                        height={170}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img4}
                        alt="Brand_Image"
                        height={180}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img5}
                        alt="Brand_Image"
                        height={250}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img6}
                        alt="Brand_Image"
                        height={220}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img7}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img1}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img2}
                        alt="Brand_Image"
                        height={180}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img3}
                        alt="Brand_Image"
                        height={250}
                      />
                    </li>
                  </ul>
                </div>
              </section>
              <p className="product-description">
                Galvanized color-coated coils are steel coils that have
                undergone a galvanization process to protect them from
                corrosion, followed by a coating of color to enhance their
                aesthetic appeal and provide additional protection. These coils
                are commonly used in construction, roofing, and various
                industrial applications due to their durability, corrosion
                resistance, and attractive appearance. The galvanization process
                involves coating the steel with a layer of zinc, which acts as a
                protective barrier against rust and corrosion.
              </p>
              <div className="row mt-5">
                <div className="col-md-5 d-flex justify-content-center mb-0 pb-0 mt-2">
                  <Link
                    to={`https://api.whatsapp.com/send?phone=+919999451600&text="I want to know more about Color Coated Coil"`}
                    target="_blank"
                  >
                    <button className="btn btn-success rounded p-2 product-whatsapp-btn">
                      <i className="fa-brands fa-whatsapp"></i> Get Best Price
                    </button>
                  </Link>
                </div>
                <div className="col-md-5 d-flex justify-content-center mt-2">
                  <Link
                    to="tel:+919999451600"
                    className="btn btn-primary btn-pill product-call-btn"
                  >
                    Call +919999451600
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row pt-5 text-center">
                <div className="col-md-6">
                  <h3>Minimum Order Quantity</h3>
                </div>
                <div className="col-md-6 product-about-data">5 Tonne</div>
              </div>
              <hr className="product-details-hr" />
              <div className="row pt-4 text-center">
                <div className="col-md-6">
                  <h3>Color</h3>
                </div>
                <div className="col-md-6 product-about-data">All</div>
              </div>
              <hr className="product-details-hr" />

              <div className="row pt-4 text-center">
                <div className="col-md-6">
                  <h3>Brand</h3>
                </div>
                <div className="col-md-6 product-about-data">
                  TATA, SAIL, JSW, AW/NS
                </div>
              </div>
              <hr className="product-details-hr" />

              <div className="row pt-4 text-center">
                <div className="col-md-6">
                  <h3>Coating Type</h3>
                </div>
                <div className="col-md-6 product-about-data">Color Coated</div>
              </div>
              <hr className="product-details-hr" />

              <div className="row pt-4 text-center">
                <div className="col-md-6">
                  <h3>Surface Treatment</h3>
                </div>
                <div className="col-md-6 product-about-data">
                  {" "}
                  Coated, Polished
                </div>
              </div>
              <hr className="product-details-hr" />

              <div className="row pt-4 text-center">
                <div className="col-md-6">
                  <h3>Application</h3>
                </div>
                <div className="col-md-6 product-about-data">Construction</div>
              </div>
              <hr className="product-details-hr" />

              <div className="row pt-4 text-center">
                <div className="col-md-6">
                  <h3>Thickness</h3>
                </div>
                <div className="col-md-6 product-about-data">0.5 MM</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
