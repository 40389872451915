import React from "react";

export default function OurTeam() {
  return (
    <div>
      <div
        className="row"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-once="true"
      >
        <div className="col-md-12">
          <p className="about-us-p">
            We have appointed a team of well qualified and skilled
            professionals. We have management personnel’s, which is hired after
            a deep inspection and assessing of their domain knowledge,
            educational ability and previous working experiences through
            predefined selection procedures. To keep pace with the modern market
            improvements and trends, we conduct regular training and seminars
            sessions to our team members within certain period of time.
          </p>
        </div>
      </div>
    </div>
  );
}
