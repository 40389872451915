import React, { useEffect, useState } from "react";
import Brief from "./brief";
import Factsheet from "./factsheet";
import OurTeam from "./OurTeam";
import WhyUs from "./WhyUs";
import QualityAssurance from "./QualityAssurance";
import Loading from "../spinner";

export default function About() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="spinner">
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <div
        className="container mt-5 mb-5 pb-3"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
      >
        <h1
          className="text-center about-us-head mb-4"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          COMPANY BRIEF
        </h1>
        <Brief />
      </div>
      <div
        className="container-fluid about-us-factsheet-main-div mb-5 mb-5 text-white text-center shadow-lg"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-once="true"
      >
        <h1
          className="text-center pt-5 pb-5"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-once="true"
        >
          FACTSHEET
        </h1>
        <Factsheet />
      </div>
      <div
        className="container mb-5 pt-2"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
      >
        <div className="row">
          <div className="col-md-12">
            <h1 className="color-blue text-center mb-4">OUR TEAM</h1>
          </div>
        </div>
        <OurTeam />
      </div>
      <div
        className="container pb-3"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
      >
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center about-us-head mb-4">WHY US</h1>
          </div>
          <WhyUs />
        </div>
      </div>
      <div
        className="container pb-5 pt-4 mb-4"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
      >
        <div className="row">
          <div className="col-md-12">
            <h1 className="color-blue text-center mb-4">Quality Assurance</h1>
            <QualityAssurance />
          </div>
        </div>
      </div>
    </div>
  );
}
