import React from "react";
import { Link } from "react-router-dom";

import ListCard from "./ListCard";

import Amber from "./Images/AMBER.png";
import EPACK from "./Images/EPACK-Packaging.png";
import Haier from "./Images/Haier.png";
import Polycab from "./Images/POLYCAB.png";
import Symphony from "./Images/Symphony.png";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function ourclients() {
  return (
    <div className="container-fluid mb-3 product-showcase-body">
      <div className="row">
        <div
          className="slider"
          data-aos="fade-up"
          data-aos-delay="800"
          data-aos-once="true"
        >
          <div className="slide-track">
            <ListCard img={Haier} url="/" />
            <ListCard img={Polycab} url="/" />
            <ListCard img={Symphony} url="/" />
            <ListCard img={Amber} url="/" />
            <ListCard img={EPACK} url="/" />
            <ListCard img={Haier} url="/" />
            <ListCard img={Polycab} url="/" />
            <ListCard img={Symphony} url="/" />
            <ListCard img={Amber} url="/" />
            <ListCard img={EPACK} url="/" />
          </div>
        </div>
      </div>
    </div>
  );
}
