import React from "react";
import img from "./Images/services-slitting.jpg";
import img2 from "./Images/services-ctl.jpg";

export default function main() {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container mt-5 mb-5 pb-5">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="text-uppercase color-blue mb-5">
              Services We Offer
            </h1>
          </div>
        </div>
        <div className="row mb-5 pb-5">
          <div className="col-md-6">
            <div class="card">
              <img src={img2} class="card-img-top" alt="..." />
              <div class="card-body text-center">
                <h5 class="card-title text-center">Cut to Length</h5>
                <a href="/" class="btn btn-primary">
                  Get Details
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div class="card">
              <img src={img2} class="card-img-top" alt="..." />
              <div class="card-body text-center">
                <h5 class="card-title text-center">Slitting</h5>
                <a href="/" class="btn btn-primary">
                  Get Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
