import React from "react";
import { Link } from "react-router-dom";
import HRCoilImg from "../Images/CardImages/hot-rolled-coils.jpg";
import HRSheetImg from "../Images/CardImages/hot-rolled-sheets.jpg";
import HRCheqImg from "../Images/CardImages/hr-cheq.jpg";
import PrimePlateImg from "../Images/CardImages/hr-prime-plate.jpg";
import SlitCoilImg from "../Images/CardImages/slit-coil.jpg";

export default function main(props) {
  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-4 mt-4">
          <div className="card text-center shadow-sm main-list-card">
            <Link to="/product/hr-coil" onClick={handleLinkClick}>
              <img
                src={HRCoilImg}
                className="card-img-top"
                alt={props.alt_text}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title card-showcase-product">HR Coil</h5>
              <Link
                to="/product/hr-coil"
                onClick={handleLinkClick}
                className="btn btn-primary m-2"
              >
                Get Details
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${props.whatsapp_number}&text="I want to know more about HR Coil"`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success m-2"
              >
                <i className="fa-brands fa-whatsapp"></i> Whatsapp Enquiry
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="card text-center shadow-sm main-list-card">
            <Link to="/product/hr-sheet" onClick={handleLinkClick}>
              <img
                src={HRSheetImg}
                className="card-img-top"
                alt={props.alt_text}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title card-showcase-product">HR Sheet</h5>
              <Link
                to="/product/hr-sheet"
                onClick={handleLinkClick}
                className="btn btn-primary m-2"
              >
                Get Details
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${props.whatsapp_number}&text="I want to know more about HR Sheet"`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success m-2"
              >
                <i className="fa-brands fa-whatsapp"></i> Whatsapp Enquiry
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="card text-center shadow-sm main-list-card">
            <Link to="/product/hr-cheq" onClick={handleLinkClick}>
              <img
                src={HRCheqImg}
                className="card-img-top"
                alt={props.alt_text}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title card-showcase-product">HR Cheq</h5>
              <Link
                to="/product/hr-cheq"
                onClick={handleLinkClick}
                className="btn btn-primary m-2"
              >
                Get Details
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${props.whatsapp_number}&text="I want to know more about HR Cheq"`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success m-2"
              >
                <i className="fa-brands fa-whatsapp"></i> Whatsapp Enquiry
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="card text-center shadow-sm main-list-card">
            <Link to="/product/prime-plate" onClick={handleLinkClick}>
              <img
                src={PrimePlateImg}
                className="card-img-top"
                alt={props.alt_text}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title card-showcase-product">Prime Plate</h5>
              <Link
                to="/product/prime-plate"
                onClick={handleLinkClick}
                className="btn btn-primary m-2"
              >
                Get Details
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${props.whatsapp_number}&text="I want to know more about Prime Plate"`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success m-2"
              >
                <i className="fa-brands fa-whatsapp"></i> Whatsapp Enquiry
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="card text-center shadow-sm main-list-card">
            <Link to="/product/hr-slit-coil" onClick={handleLinkClick}>
              <img
                src={SlitCoilImg}
                className="card-img-top"
                alt={props.alt_text}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title card-showcase-product">
                {" "}
                HR Slit Coil
              </h5>
              <Link
                to="/product/hr-slit-coil"
                onClick={handleLinkClick}
                className="btn btn-primary m-2"
              >
                Get Details
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${props.whatsapp_number}&text="I want to know more about Slit Coil"`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success m-2"
              >
                <i className="fa-brands fa-whatsapp"></i> Whatsapp Enquiry
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
