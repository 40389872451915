import { React, useState, useEffect } from "react";
import backgroundImage from "../../Images/bg-curve-line.png";
// import backgroundImage from "../../Images/bg-obj.png";
// import backgroundImage from "../../Images/bg-lines.jpg";
import HRProducts from "./ProductDetails/HR/main";
import CRProducts from "./ProductDetails/CR/main";
import GPProducts from "./ProductDetails/GP/main";
import ColorCoatedProducts from "./ProductDetails/ColorCoated/main";
import ColorPipeProducts from "./ProductDetails/PIPE/main";
import GPSPProducts from "./ProductDetails/GPSP/main";
import PPGIProducts from "./ProductDetails/PPGI/main";

import Loading from "../spinner";

export default function AllProducts() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="spinner">
        <Loading />
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // position: "center",
        backgroundPosition: "right bottom",
        // margin: "50% top",
      }}
    >
      <div className="container mt-5 pt-5">
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-once="true"
        >
          <h2 className="product-page-head text-center mt-5 mb-2">
            PRODUCT LINE
          </h2>
          <p className="product-page-head-para text-center mb-5">
            Checkout the range of world class product we have in stock.
          </p>
        </div>
      </div>
      <div className="container-fluid mt-5 mb-5">
        {/* <div className="row">
          <div className="col-md-6 d-flex justify-content-center">
            <h2 className="text-uppercase color-blue">Search Products</h2>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            Search Field here...
          </div>
        </div> */}
      </div>
      <div className="container-fluid mb-5 pb-5">
        <div className="row mb-5">
          <div
            className="col-md-2 mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <h1 className="text-center product-line-name">Hot Rolled</h1>
            <h1 className="text-center product-line-name">Products</h1>
          </div>
          <div
            className="col-md-10"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
          >
            <HRProducts
              whatsapp_number="+919999451600"
              alt_text="Product_Images"
            />
          </div>
        </div>

        <div className="row mb-5">
          <div
            className="col-md-2 mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <h1 className="text-center product-line-name">Cold Rolled</h1>
            <h1 className="text-center product-line-name">Products</h1>
          </div>
          <div
            className="col-md-10"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
          >
            <CRProducts
              whatsapp_number="+919999451600"
              alt_text="Product_Images"
            />
          </div>
        </div>

        <div className="row mb-5">
          <div
            className="col-md-2 mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <h1 className="text-center product-line-name">Galvanized Plain</h1>
            <h1 className="text-center product-line-name">Products</h1>
          </div>
          <div
            className="col-md-10"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
          >
            <GPProducts
              whatsapp_number="+919999451600"
              alt_text="Product_Images"
            />
          </div>
        </div>

        <div className="row mb-5">
          <div
            className="col-md-2 mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <h1 className="text-center product-line-name">GPSP</h1>
            <h1 className="text-center product-line-name">Products</h1>
          </div>
          <div
            className="col-md-10"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
          >
            <GPSPProducts
              whatsapp_number="+919999451600"
              alt_text="Product_Images"
            />
          </div>
        </div>

        <div className="row mb-5">
          <div
            className="col-md-2 mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <h1 className="text-center product-line-name">PPGI</h1>
            <h1 className="text-center product-line-name">Products</h1>
          </div>
          <div
            className="col-md-10"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
          >
            <PPGIProducts
              whatsapp_number="+919999451600"
              alt_text="Product_Images"
            />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6">
            <div className="row">
              <div
                className="col-md-4"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-once="true"
              >
                <h1 className="text-center product-line-name pt-3">
                  Color Coated
                </h1>
                <h1 className="text-center product-line-name">Products</h1>
              </div>
              <div
                className="col-md-8"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-once="true"
              >
                <ColorCoatedProducts
                  whatsapp_number="+919999451600"
                  alt_text="Product_Images"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div
                className="col-md-3"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-once="true"
              >
                <h1 className="text-center product-line-name pt-3">PIPE</h1>
                <h1 className="text-center product-line-name">Products</h1>
              </div>
              <div
                className="col-md-8"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-once="true"
              >
                <ColorPipeProducts
                  whatsapp_number="+919999451600"
                  alt_text="Product_Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
