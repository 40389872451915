import React, { useRef, useState } from "react";
import NavLogo from "../../Images/ph-logo.png";
import { Link } from "react-router-dom";
import pdf from ".././Brochure/ph-steels.pdf";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Navbar = () => {
  const navbarToggler = useRef();

  const handleLinkClick = () => {
    navbarToggler.current.click();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav
      className="navbar navbar-expand-lg bg-light p-2 shadow-lg fixed-top"
      data-aos="fade-up"
      data-aos-delay="300"
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src={NavLogo}
            alt="Logo"
            width="90"
            height="90"
            data-aos="fade-up"
            data-aos-delay="450"
            data-aos-once="true"
            onClick={scrollToTop}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          data-aos="fade-down"
          data-aos-delay="550"
          data-aos-once="true"
          ref={navbarToggler}
          onClick={scrollToTop}
        >
          <span className="fa-solid fa-bars-staggered"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <Link
              to="/"
              className="nav-a m-3 hover-underline-animation"
              data-aos="fade-up"
              data-aos-delay="950"
              data-aos-once="true"
              onClick={handleLinkClick}
            >
              <i className="fa-solid fa-house"></i> <span> Home</span>
            </Link>
            <Link
              to="/about"
              className="nav-a m-3 hover-underline-animation"
              data-aos="fade-up"
              data-aos-delay="650"
              data-aos-once="true"
              onClick={handleLinkClick}
            >
              <i className="fa-solid fa-user"></i> <span>About Us</span>
            </Link>
            <Link
              to="/products"
              className="nav-a m-3 hover-underline-animation"
              data-aos="fade-up"
              data-aos-delay="750"
              data-aos-once="true"
              onClick={handleLinkClick}
            >
              <i className="fa-solid fa-list"></i> <span> Products</span>
            </Link>
            <Link
              to="/services"
              className="nav-a m-3 hover-underline-animation"
              data-aos="fade-up"
              data-aos-delay="750"
              data-aos-once="true"
              onClick={handleLinkClick}
            >
              <i className="fa-solid fa-wrench"></i> <span> Services</span>
            </Link>
            <a
              href={pdf}
              download="ph-steels"
              target="_blank"
              rel="noreferrer"
              className="nav-a m-3 hover-underline-animation"
              data-aos="fade-up"
              data-aos-delay="850"
              data-aos-once="true"
              onClick={handleLinkClick}
            >
              <i className="fa-solid fa-arrow-down"></i>{" "}
              <span>Download Brochure</span>
            </a>

            <Link
              to="/contact"
              className="nav-a m-3 hover-underline-animation"
              data-aos="fade-up"
              data-aos-delay="650"
              data-aos-once="true"
              onClick={handleLinkClick}
            >
              <i className="fa-solid fa-phone"></i>{" "}
              <span> Contact Details</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
