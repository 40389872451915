import { React, useState, useEffect } from "react";
import Loading from "../../../spinner";
import { Link } from "react-router-dom";
import img1 from "../Images/HR/slit-coil-1.jpg";
import img2 from "../Images/HR/slit-coil-2.jpg";
import img3 from "../Images/HR/slit-coil-3.jpg";
import img4 from "../Images/HR/slit-coil-4.jpg";
import img5 from "../Images/HR/slit-coil-5.jpg";
import img6 from "../Images/HR/slit-coil-6.jpg";
import img7 from "../Images/HR/slit-coil-7.jpg";

export default function PrimePlate() {
  // const handleLinkClick = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 550);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="spinner">
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="container-fluid mb-5 pb-5">
          <div className="row">
            <div className="col-md-6 pt-3">
              <Link to="/products">
                <p className="hover-underline-animation">
                  <i className="fa-solid fa-arrow-left"></i> Back to Products
                </p>
              </Link>
              <section id="marquee__container">
                <h2
                  className="marquee__title"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-once="true"
                >
                  HR Slit Coil
                </h2>

                <div
                  className="marquee"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  <ul className="marquee__content">
                    <li>
                      <img
                        className="marquee__img"
                        src={img1}
                        alt="Brand_Image"
                        height={150}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img2}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img3}
                        alt="Brand_Image"
                        height={250}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img4}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img5}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img6}
                        alt="Brand_Image"
                        height={220}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img7}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img1}
                        alt="Brand_Image"
                        height={150}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img2}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                    <li>
                      <img
                        className="marquee__img"
                        src={img3}
                        alt="Brand_Image"
                        height={200}
                      />
                    </li>
                  </ul>
                </div>
              </section>
              <p
                className="product-description"
                data-aos="fade-up"
                data-aos-delay="350"
                data-aos-once="true"
              >
                HR slit coil refers to hot-rolled steel coil that has been
                processed into narrower widths through slitting. This process
                involves cutting wide coils into smaller strips to meet specific
                size requirements. HR slit coils are commonly used in various
                industries for manufacturing purposes due to their consistent
                quality and dimensional accuracy.
              </p>
              <div className="row mt-5">
                <div className="col-md-5 d-flex justify-content-center mb-0 pb-0 mt-2">
                  <Link
                    to={`https://api.whatsapp.com/send?phone=+919999451600&text="I want to know more about Slit Coil"`}
                    target="_blank"
                  >
                    <button className="btn btn-success rounded p-2 product-whatsapp-btn">
                      <i className="fa-brands fa-whatsapp"></i> Get Best Price
                    </button>
                  </Link>
                </div>
                <div className="col-md-5 d-flex justify-content-center mt-2">
                  <Link
                    to="tel:+919999451600"
                    className="btn btn-primary btn-pill product-call-btn"
                  >
                    Call +919999451600
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="row pt-5 text-center"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <div className="col-md-6">
                  <h3>Thickness</h3>
                </div>
                <div className="col-md-6 product-about-data"> 5 mm</div>
              </div>
              <hr className="product-details-hr" />
              <div
                className="row pt-4 text-center"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <div className="col-md-6">
                  <h3>Grade</h3>
                </div>
                <div className="col-md-6 product-about-data">2062/1079</div>
              </div>
              <hr className="product-details-hr" />

              <div
                className="row pt-4 text-center"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <div className="col-md-6">
                  <h3>Tensile Strength</h3>
                </div>
                <div className="col-md-6 product-about-data">
                  250 to 600 MPa
                </div>
              </div>
              <hr className="product-details-hr" />

              <div
                className="row pt-4 text-center"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <div className="col-md-6">
                  <h3>Surface Treatment</h3>
                </div>
                <div className="col-md-6 product-about-data"> Grey</div>
              </div>
              <hr className="product-details-hr" />

              <div
                className="row pt-4 text-center"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <div className="col-md-6">
                  <h3>Minimum Order Quantity</h3>
                </div>
                <div className="col-md-6 product-about-data"> 1000 Kg</div>
              </div>
              <hr className="product-details-hr" />

              <div
                className="row pt-4 text-center"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-once="true"
              >
                <div className="col-md-6">
                  <h3>Brands</h3>
                </div>
                <div className="col-md-6 product-about-data">
                  TATA, SAIL, JSW, JSPL, AW/NS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
