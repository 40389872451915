import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";

const YouTubeVideo = ({ video_id }) => {
  const [playerSize, setPlayerSize] = useState({ width: "640", height: "390" });

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let newWidth = "640";
      let newHeight = "390";

      // Adjust dimensions based on screen width
      if (windowWidth <= 480) {
        newWidth = "300";
        newHeight = "180";
      } else if (windowWidth <= 768) {
        newWidth = "480";
        newHeight = "270";
      } else if (windowWidth <= 1024) {
        newWidth = "640";
        newHeight = "360";
      } else {
        newWidth = "800";
        newHeight = "450";
      }

      setPlayerSize({ width: newWidth, height: newHeight });
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial call to set dimensions
    handleResize();

    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <YouTube
      videoId={video_id}
      opts={{ ...playerSize, playerVars: { autoplay: 0 } }}
    />
  );
};

export default YouTubeVideo;
