import "./App.css";
import Banner from "./components/front-page/main";
import NavBar from "./components/navbar/NavBar";
import About from "./components/AboutUs/About";
import Services from "./components/Services/main";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VideoPage from "./components/CorporateVideo/CorporateVideo";
// import Terms from "./components/Terms";
import Contact from "./components/contact";
// import ReviewPage from "./components/ReviewPage/main";
import AllProducts from "./components/Products/allProducts";
import ProductHRCoil from "./components/Products/ProductDetails/HR/HRCoil";
import ProductHRSheet from "./components/Products/ProductDetails/HR/HRSheet";
import ProductHRCheq from "./components/Products/ProductDetails/HR/HRCheq";
import ProductHRPrimePlate from "./components/Products/ProductDetails/HR/PrimePlate";
import ProductHRSlitCoil from "./components/Products/ProductDetails/HR/SlitCoil";

import ProductCRCoil from "./components/Products/ProductDetails/CR/CRCoil";
import ProductCRSlitCoil from "./components/Products/ProductDetails/CR/CRSlitCoil";
import ProductCRSheet from "./components/Products/ProductDetails/CR/CRSheet";
import ProductCRPrimePkt from "./components/Products/ProductDetails/CR/CRPrimePkt";

import ProductGPCoil from "./components/Products/ProductDetails/GP/GPCoil";
import ProductGPSlitCoil from "./components/Products/ProductDetails/GP/GPSlitCoil";
import ProductGPSheet from "./components/Products/ProductDetails/GP/GPSheet";
import ProductGPPrimePkt from "./components/Products/ProductDetails/GP/GPPrimePkt";

import ProductColorCoatedCoil from "./components/Products/ProductDetails/ColorCoated/ColorCoatedCoil";

import ProductApolloPipe from "./components/Products/ProductDetails/PIPE/ApolloPipe";

import NotFoundPage from "./components/NotFound";

function App() {
  return (
    <>
      <Router>
        <div className="prevent-select">
          <NavBar />
          <Routes>
            <Route path="/" element={<Banner />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/corporate-video" element={<VideoPage />} />
            {/* <Route path="/terms-and-conditions" element={<Terms />} /> */}
            <Route path="/products" element={<AllProducts />} />
            {/* <Route path="/reviews" element={<ReviewPage />} /> */}
            {/* x */}
            <Route path="/product/hr-coil" element={<ProductHRCoil />} />
            <Route path="/product/hr-sheet" element={<ProductHRSheet />} />
            <Route path="/product/hr-cheq" element={<ProductHRCheq />} />
            <Route
              path="/product/hr-slit-coil"
              element={<ProductHRSlitCoil />}
            />
            <Route
              path="/product/prime-plate"
              element={<ProductHRPrimePlate />}
            />

            <Route path="/product/cr-coil" element={<ProductCRCoil />} />
            <Route
              path="/product/cr-slit-coil"
              element={<ProductCRSlitCoil />}
            />
            <Route path="/product/cr-sheet" element={<ProductCRSheet />} />
            <Route
              path="/product/cr-prime-pkt"
              element={<ProductCRPrimePkt />}
            />

            <Route path="/product/gp-coil" element={<ProductGPCoil />} />
            <Route
              path="/product/gp-slit-coil"
              element={<ProductGPSlitCoil />}
            />
            <Route path="/product/gp-sheet" element={<ProductGPSheet />} />
            <Route
              path="/product/gp-prime-pkt"
              element={<ProductGPPrimePkt />}
            />

            <Route
              path="/product/color-coated-coil"
              element={<ProductColorCoatedCoil />}
            />

            <Route
              path="/product/apollo-pipe"
              element={<ProductApolloPipe />}
            />

            <Route path="*" element={<NotFoundPage />}></Route>
          </Routes>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
