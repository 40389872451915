import React from "react";

export default function brief() {
  return (
    <div>
      <p
        className="about-us-p"
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
      >
        Established in the year{" "}
        <b>
          <span className="color-blue">2014</span>
        </b>
        , at Ghaziabad (Uttar Pradesh, India), we{" "}
        <b>
          <span className="color-blue">“PH Steels”</span>
        </b>
        , are engaged in trading and supplying a wide range of Steel Products.
        Product array provided by us includes Steel Channels, Steel Angles and
        Steel Bars. These products are extensively used in general purpose
        concrete reinforcement structures, industrial structures, high-rise
        buildings, bridges and flyovers, thermal and hydro power plants, etc.
        Sourced from certified vendors of the market, the offered products are
        manufactured using the top-notch quality raw material and advanced
        technology. Our offered products are widely admired by our esteemed
        clients owing to their incomparable features like optimum strength,
        dimensional accuracy, high ductility, bendability and weldability, high
        thermal resistance and rust proof nature. The offered products are also
        available in different specifications for our esteemed clients at market
        leading prices. Besides, we also render highly reliable Slitting
        Services, Coil Cutting Services and Steel Sheet Cutting Services. These
        services are broadly appreciated by our clients for their perfect
        execution, reliability and cost effective prices. Also, we are a reputed
        stockists of SAIL. Backed by the support of our authentic and certified
        vendors of the industry, we are able to deliver high quality bars, rods
        and other allied products which are widely utilized in construction
        related works. We have selected our vendors after a thorough analysis of
        their product quality, past records, market reputation, financial
        stability and delivery schedule. In order to store these products in a
        safe way, we have established a well-facilitated and spacious
        warehousing unit. Further, we have also managed good relationship with
        our trusted vendors to provide all these products with high quality. Our
        team of skilled professionals work in a close-coordination with each
        other in order to provide this quality approved range of products. Owing
        to our ethical business practices, quality conscious approach,
        transparent business dealings, easy payment modes and prompt delivery,
        we have become the prior choice of our esteemed clients. We trade our
        products under the brand name of Rathi. Our successful reputation is
        backed by the meticulous planning of our leader and mentor{" "}
        <b>
          <span className="color-blue">Mr. Vikas Goel</span>
        </b>
        (Partner). He is blessed with rich industry experience and always guides
        us to remain true to our values of integrity and hard work. Under his
        guidance we are capable to maintain our ethics while building a strong
        relationship with our clients.
      </p>
    </div>
  );
}
