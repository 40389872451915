import React from "react";
import { Link } from "react-router-dom";

import ListCard from "./ListCard";

import crcoils from "../Product-Images/cr-coils-img.png";
import crsheets from "../Product-Images/cr-sheets.jpg";
import gpsheets from "../Product-Images/gp-sheets.jpg";
import gpcoils from "../Product-Images/gp-coils.jpg";
import galcoilsheets from "../Product-Images/gal-coil-sheets.jpg";
import hotrolledcoils from "../Product-Images/hot-rolled-coils.jpg";
import hotrolledsheets from "../Product-Images/hot-rolled-sheets.jpg";

import hrcheq from "../../Products/ProductDetails/Images/CardImages/hr-cheq.jpg";
import hrprimeplate from "../../Products/ProductDetails/Images/CardImages/hr-prime-plate.jpg";
import hrslitcoil from "../../Products/ProductDetails/Images/CardImages/slit-coil.jpg";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function ourProducts() {
  return (
    <div className="container-fluid mt-5 mb-3 product-showcase-body">
      <div className="container-fluid">
        <div className="row">
          <div
            className="slider"
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-once="true"
          >
            <div className="slide-track">
              <ListCard
                img={crcoils}
                url="/product/cr-coil"
                title="Cold Rolled (CR) Coils"
              />
              <ListCard
                img={crsheets}
                url="/product/cr-sheet"
                title="Cold Rolled (CR) Sheets"
              />
              <ListCard
                img={gpsheets}
                url="/product/gp-sheet"
                title="GP Sheets"
              />
              <ListCard img={gpcoils} url="/product/gp-coil" title="GP Coils" />
              <ListCard
                img={galcoilsheets}
                url="/product/galvonised-coil-sheet"
                title="Galvanised Coil Sheets"
              />
              <ListCard
                img={hotrolledcoils}
                url="/product/hr-coil"
                title="Hot Rolled Coils"
              />
              <ListCard
                img={hotrolledsheets}
                url="/product/hr-sheet"
                title="Hot Rolled Sheets"
              />
              <ListCard
                img={crcoils}
                url="/product/cr-coil"
                title="Cold Rolled Coil"
              />
              <ListCard
                img={crsheets}
                url="/product/cr-sheet"
                title="Cold Rolled Sheets"
              />
              <ListCard
                img={gpsheets}
                url="/product/gp-sheet"
                title="GP Sheets"
              />
              <ListCard img={gpcoils} url="/product/gp-coil" title="GP Coil" />
              <ListCard
                img={galcoilsheets}
                url="/product/galvonised-coil-sheet"
                title="Galvanised Coil Sheets"
              />
              <ListCard
                img={hotrolledcoils}
                url="/product/hr-coil"
                title="Hot Rolled Coils"
              />
              <ListCard
                img={hrcheq}
                url="/product/hr-cheq"
                title="Hot Rolled Cheq"
              />
              <ListCard
                img={hrprimeplate}
                url="/product/hr-prime-plate"
                title="Hot Rolled Prime Plate"
              />
              <ListCard
                img={hrslitcoil}
                url="/product/hr-slit-coil"
                title="Hot Rolled Slit Coil"
              />
              <ListCard
                img={hotrolledsheets}
                url="/product/hr-sheet"
                title="Hot Rolled Sheets"
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4"></div>
            <div className="col-md-4 d-flex justify-content-center">
              <Link to="/products">
                <button
                  className="btn btn-light btn-lg showcase-all-products-btn"
                  onClick={scrollToTop}
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  View all Products
                </button>
              </Link>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
