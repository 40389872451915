import React from "react";
import APL from "./Images/Apl.png";
import JSW from "./Images/JSW.png";
import POSCO from "./Images/POSCO.png";
import Sail from "./Images/Seal.png";
import TataSteel from "./Images/TataSteel.png";

export default function main() {
  return (
    <>
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <h3
              className="text-center"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-once="true"
              style={{ fontSize: "32px", color: "#1C63B8" }}
            >
              ━━━ Authorised Distributor ━━━
            </h3>
            <h1
              className="text-center"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-once="true"
              style={{ color: "#1C63B8" }}
            >
              We are authorised distributor of quality steel products
            </h1>
          </div>
        </div>
        <div className="row text-center mb-5">
          <div className="col-md-1"></div>
          <div className="col-md-2">
            <img src={TataSteel} alt="Tata Steel" height={180} width={180} />
          </div>
          <div className="col-md-2">
            <img src={APL} alt="APL" height={180} width={180} />
          </div>
          <div className="col-md-2">
            <img src={POSCO} alt="POSCO" height={180} width={180} />
          </div>
          <div className="col-md-2">
            <img src={Sail} alt="Sail" height={160} width={160} />
          </div>
          <div className="col-md-2">
            <img src={JSW} alt="JSW" height={180} width={180} />
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </>
  );
}
