import React from "react";
import { Link } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function ListCard(props) {
  return (
    <Link
      to={props.url}
      onClick={scrollToTop}
      className="slide-client p-1"
      data-aos="fade-up"
      data-aos-delay="900"
      data-aos-once="true"
    >
      <img src={props.img} height="250" width="330" alt="Product_Image" />
    </Link>
  );
}
