import React from "react";
import ClientShowCase from "./ClientShowCase/ClientShowCase";

export default function clientsShow() {
  return (
    <>
      <ClientShowCase />
    </>
  );
}
