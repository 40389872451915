import React from "react";
import { Link } from "react-router-dom";
import ColorCoatedImg from "../Images/CardImages/color-coated.jpg";

export default function main(props) {
  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-10 mt-4">
          <div className="card text-center shadow-sm main-list-card">
            <Link to="/product/color-coated-coil" onClick={handleLinkClick}>
              <img
                src={ColorCoatedImg}
                className="card-img-top"
                alt={props.alt_text}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title card-showcase-product">
                Color Coated Coil
              </h5>
              <Link
                to="/product/color-coated-coil"
                onClick={handleLinkClick}
                className="btn btn-primary m-2"
              >
                Get Details
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${props.whatsapp_number}&text="I want to know more about Color Coated Coil"`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success m-2"
              >
                <i className="fa-brands fa-whatsapp"></i> Whatsapp Enquiry
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
