import React from "react";

export default function QualityAssurance() {
  return (
    <div data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
      <p className="about-us-p">
        We are offering a broad spectrum of steel channel and angels for our
        prestigious clients. We are also providing a quality assured array of
        slitting, coil cutting and steel sheet cutting services. We are a client
        centric organization always believes in making a long term relationship
        with the clients. Owing to our quality assured products and fair
        business practiced, we have attained a huge number of reputed clients.
      </p>
    </div>
  );
}
