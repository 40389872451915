import React from "react";
import { Link } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function ListCard(props) {
  return (
    <Link
      to={props.url}
      onClick={scrollToTop}
      className="slide p-1"
      data-aos="fade-up"
      data-aos-delay="900"
      data-aos-once="true"
    >
      <img src={props.img} height="350" width="430" alt="Product_Image" />
      <div className="row text-center text-white pt-2 p-1">
        <h3>{props.title}</h3>
        <p>Click to view</p>
      </div>
    </Link>
  );
}
