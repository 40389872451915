import React, { useState, useEffect } from "react";
import Loading from "./spinner";

export default function Contact() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 250);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="spinner">
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1
        className="text-center contact-page-head mt-4 text-uppercase"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-once="true"
      >
        Contact Details
      </h1>

      <div className="container mb-5 pb-5 contact-main-div p-2">
        <div className="row mt-4">
          <div className="col-sm-6 mt-3">
            <div
              className="card"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-once="true"
            >
              <div className="card-body">
                <h3 className="card-title text-center color-blue">
                  Ajay Rawat
                </h3>
                <p className="card-text text-center">Sales Executive</p>
                <a href="tel:+918851378565" target="_blank" rel="noreferrer">
                  <h4 className="text-center color-blue">
                    <i class="fa-solid fa-phone"></i> 8851378565
                  </h4>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 mt-3">
            <div
              className="card"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-once="true"
            >
              <div className="card-body">
                <h3 className="card-title text-center color-blue">
                  Vikas Goel
                </h3>
                <p className="card-text text-center">Director</p>
                <div className="row">
                  <a href="tel:+919999451600" target="_blank" rel="noreferrer">
                    <h4 className="text-center color-blue">
                      <i class="fa-solid fa-phone"></i> 9999451600
                    </h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-md-12 d-flex justify-content-center pb-4">
            <h3
              className="color-blue"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-once="true"
            >
              Whatsapp your queries to us, We will get back to you!
            </h3>
          </div>
          <div className="col-md-12 d-flex justify-content-center">
            <a href="https://wa.link/c22ebt" target="_blank" rel="noreferrer">
              <button
                className="btn btn-success btn-lg"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-once="true"
              >
                <i class="fa-brands fa-whatsapp"></i> Whatsapp us
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
