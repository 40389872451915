import React from "react";
import { Link } from "react-router-dom";
import CRCoilImg from "../Images/CardImages/cr-coil.jpg";
import HRSheetImg from "../Images/CardImages/hot-rolled-sheets.jpg";
// import HRCheqImg from "../Images/CardImages/hr-cheq.webp";
// import GPPrimePkt from "../Images/CardImages/gp-prime-pkt.webp";

export default function main(props) {
  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-4 mt-4">
          <div className="card text-center shadow-sm main-list-card">
            <Link to="/product/ppgi-coil" onClick={handleLinkClick}>
              <img
                src={CRCoilImg}
                className="card-img-top"
                alt={props.alt_text}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title card-showcase-product">PPGI Coil</h5>
              <Link
                to="/product/ppgi-coil"
                onClick={handleLinkClick}
                className="btn btn-primary m-2"
              >
                Get Details
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${props.whatsapp_number}&text="I want to know more about PPGI Coil"`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success m-2"
              >
                <i className="fa-brands fa-whatsapp"></i> Whatsapp Enquiry
              </Link>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="card text-center shadow-sm main-list-card">
            <Link to="/product/ppgi-sheet" onClick={handleLinkClick}>
              <img
                src={HRSheetImg}
                className="card-img-top"
                alt={props.alt_text}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title card-showcase-product">PPGI Sheet</h5>
              <Link
                to="/product/ppgi-sheet"
                onClick={handleLinkClick}
                className="btn btn-primary m-2"
              >
                Get Details
              </Link>
              <Link
                to={`https://api.whatsapp.com/send?phone=${props.whatsapp_number}&text="I want to know more about PPGI Sheet"`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success m-2"
              >
                <i className="fa-brands fa-whatsapp"></i> Whatsapp Enquiry
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
