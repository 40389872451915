import React from "react";
import Testimonial from "./testimonial";
// import { Link } from "react-router-dom";

export default function rating() {
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  return (
    <div className="container-fluid mt-5 pt-3 mb-5">
      <div className="row text-center">
        <h1
          className="rating-heading"
          data-aos="fade-up"
          data-aos-delay="900"
          data-aos-once="true"
        >
          ━ Customer Reviews ━
        </h1>
        <p
          className="rating-p"
          data-aos="fade-up"
          data-aos-delay="950"
          data-aos-once="true"
        >
          Discover why customers rave about our products in their own words!
        </p>
      </div>
      <div className="row text-center">
        <div className="row pb-lg-3">
          <div className="col-md-1"></div>
          <div className="col-md-3 mt-lg-3">
            <span
              className="rating-final"
              data-aos="fade-up"
              data-aos-delay="900"
              data-aos-once="true"
            >
              4
            </span>
            <span
              className="rating-total"
              data-aos="fade-up"
              data-aos-delay="700"
              data-aos-once="true"
            >
              {" "}
              / 5{" "}
            </span>
            <div className="rating-stars">
              <i
                className="fa-solid fa-star"
                data-aos="fade-up"
                data-aos-delay="500"
                data-aos-once="true"
              ></i>
              <i
                className="fa-solid fa-star"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-once="true"
              ></i>
              <i
                className="fa-solid fa-star"
                data-aos="fade-up"
                data-aos-delay="700"
                data-aos-once="true"
              ></i>
              <i
                className="fa-solid fa-star"
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-once="true"
              ></i>
              <i
                className="fa-regular fa-star"
                data-aos="fade-up"
                data-aos-delay="900"
                data-aos-once="true"
              ></i>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row mt-md-3 pt-4 pb-5 mb-lg-5 d-flex justify-content-between">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 250 250"
                  className="circular-progress-one"
                >
                  <circle className="bg"></circle>
                  <circle className="fg"></circle>
                </svg>
                <h3
                  className="rating-circle-text"
                  data-aos="fade-right"
                  data-aos-delay="500"
                  data-aos-once="true"
                >
                  Response
                </h3>
              </div>
              <div className="col-md-2">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 250 250"
                  className="circular-progress-two"
                >
                  <circle className="bg"></circle>
                  <circle className="fg"></circle>
                </svg>
                <h3
                  className="rating-circle-text"
                  data-aos="fade-right"
                  data-aos-delay="550"
                  data-aos-once="true"
                >
                  Quality
                </h3>
              </div>
              <div className="col-md-2">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 250 250"
                  className="circular-progress-three"
                >
                  <circle className="bg"></circle>
                  <circle className="fg"></circle>
                </svg>
                <h3
                  className="rating-circle-text"
                  data-aos="fade-right"
                  data-aos-delay="600"
                  data-aos-once="true"
                >
                  Delivery
                </h3>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
    </div>
  );
}
