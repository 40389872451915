import React from "react";
import ClientShow from "./clientsShow";

export default function ourClients() {
  return (
    <>
      <div className="container-fluid mb-5 pb-5 pt-4">
        <h1 className="clients-heading text-center">━ Our Clients ━</h1>
        <p className="clients-p text-center">
          Trusted by industry leaders, powered by innovation.
        </p>
        <div className="pt-5">
          <ClientShow />
        </div>
      </div>
    </>
  );
}
