import React from "react";
// import backgroundImage from "../../Images/test.gif";
// import backgroundImage from "../../Images/banner-bg.gif";
import { Link } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function Banner() {
  return (
    <>
      <div className="container-fluid pb-5 pt-5 shadow-sm banner-main-div">
        <div
          className="container pb-5"
          data-aos="slide-right"
          data-aos-delay="800"
          data-aos-once="true"
        >
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="row mt-5">
            <h1 className="text-white banner-heading text-center">
              <span
                className="banner-heading"
                data-aos="fade-up"
                data-aos-delay="1100"
                data-aos-once="true"
              >
                Forging Strength
              </span>
              ,{" "}
              <span
                className="banner-heading"
                data-aos="fade-up"
                data-aos-delay="1800"
                data-aos-once="true"
              >
                Building Tomorrow
              </span>
            </h1>
            <h3
              className="text-center mt-3 pt-4 text-white banner-text"
              data-aos="fade-in"
              data-aos-delay="2500"
              data-aos-once="true"
            >
              We are a <strong>leading organization</strong> involved in
              Wholesale Trader and Supplier wide assortment of{" "}
              <strong>
                Industrial Coils, Mild Steel Plates And Sheets, Industrial
                Products, Mild Steel Pipe, Steel Bars, MS Round Bar
              </strong>
              , for our precious patrons.
            </h3>
          </div>
          <div className="row mt-5">
            <div
              className="col d-flex justify-content-center"
              data-aos="slide-up"
              data-aos-delay="2800"
              data-aos-once="true"
            >
              <Link className="text-white" to="/contact" onClick={scrollToTop}>
                <button className="btn btn-primary btn-lg p-3 px-5 shadow-lg">
                  <i className="fa-solid fa-address-card"></i> Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
