import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p class="zoom-area">Page you are looking for does not exists!</p>
      <section class="error-container">
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
        <span class="zero">
          <span class="screen-reader-text">0</span>
        </span>
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
      </section>
      <div class="link-container pb-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Link to={"/"} class="more-link" rel="noreferrer">
                Back to Home
              </Link>
            </div>
            <div className="col-md-6">
              <Link to={"/products"} class="more-link" rel="noreferrer">
                Explore our Products
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
