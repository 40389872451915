import React from "react";
import Image from "../../Images/welcomeimage.jpg";
import { Link } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export default function WelcomePage() {
  return (
    <div>
      <div className="container mt-5 pt-5 pb-5 mb-5">
        <div className="row">
          <div
            className="col-md-5 shadow-lg"
            style={{
              backgroundImage: `url(${Image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "10px",
            }}
            data-aos="slide-right"
            data-aos-delay="1200"
            data-aos-once="true"
          ></div>
          <div className="col-md-1"></div>
          <div
            className="col-md-6"
            data-aos="slide-right"
            data-aos-delay="600"
            data-aos-once="true"
          >
            <h2
              className="welcome-text-color text-center"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-once="true"
            >
              ━ Welcome to PH Steels ━
            </h2>
            <h1
              className="welcome-text-color text-center"
              data-aos="fade-up"
              data-aos-delay="700"
              data-aos-once="true"
            >
              Overall experience of more than 20 years in this sector
            </h1>
            <br />
            <h5
              className="welcome-text"
              data-aos="fade-up"
              data-aos-delay="900"
              data-aos-once="true"
            >
              We have been actively engaged in trading and supplying a
              comprehensive range of Steel Products. Product array provided by
              us includes Steel Channels, Steel Angles and Steel Bars. Sourced
              from authentic vendors of the market, our offered products are
              manufactured using optimum grade raw material and advanced
              technology. Further, the offered products are highly acclaimed for
              their high strength, corrosion resistance, optimum thermal
              resistance and rust proof nature. We also provide quality assured
              Slitting Services, Coil Cutting Services and Steel Sheet Cutting
              Services. These services are broadly appreciated by our clients
              for their perfect execution, reliability and cost effective
              prices.
            </h5>
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-md-6">
                  <i
                    className="fa-solid fa-circle-check"
                    data-aos="fade-up"
                    data-aos-delay="850"
                    data-aos-once="true"
                  ></i>{" "}
                  <span
                    data-aos="fade-in"
                    data-aos-delay="850"
                    data-aos-once="true"
                  >
                    Steel Angles
                  </span>
                  <br />
                  <i
                    className="fa-solid fa-circle-check"
                    data-aos="fade-up"
                    data-aos-delay="900"
                    data-aos-once="true"
                  ></i>{" "}
                  <span
                    data-aos="fade-in"
                    data-aos-delay="900"
                    data-aos-once="true"
                  >
                    CR Sheet
                  </span>
                  <br />
                  <i
                    className="fa-solid fa-circle-check"
                    data-aos="fade-up"
                    data-aos-delay="950"
                    data-aos-once="true"
                  ></i>{" "}
                  <span
                    data-aos="fade-in"
                    data-aos-delay="950"
                    data-aos-once="true"
                  >
                    Channel
                  </span>
                </div>
                <div className="col-md-6">
                  <i
                    className="fa-solid fa-circle-check"
                    data-aos="fade-up"
                    data-aos-delay="850"
                    data-aos-once="true"
                  ></i>{" "}
                  <span
                    data-aos="fade-in"
                    data-aos-delay="850"
                    data-aos-once="true"
                  >
                    CR Coil
                  </span>
                  <br />
                  <i
                    className="fa-solid fa-circle-check"
                    data-aos="fade-up"
                    data-aos-delay="900"
                    data-aos-once="true"
                  ></i>{" "}
                  <span
                    data-aos="fade-in"
                    data-aos-delay="900"
                    data-aos-once="true"
                  >
                    MS Plate
                  </span>
                  <br />
                  <i
                    className="fa-solid fa-circle-check"
                    data-aos="fade-up"
                    data-aos-delay="950"
                    data-aos-once="true"
                  ></i>{" "}
                  <span
                    data-aos="fade-in"
                    data-aos-delay="950"
                    data-aos-once="true"
                  >
                    Mild Steel Pipe
                  </span>
                </div>
                <div className="col-12 d-flex justify-content-center mt-5">
                  <Link
                    to="/about"
                    className="welcome-learn-more"
                    onClick={scrollToTop}
                  >
                    <button
                      className="btn btn-outline-primary btn-lg shadow-sm"
                      data-aos="fade-up"
                      data-aos-delay="1000"
                      data-aos-once="true"
                    >
                      About Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
