import React from "react";

export default function WhyUs() {
  return (
    <div>
      <p
        className="about-us-p"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-once="true"
      >
        We are a distinct resource to avail the provided products and services.
        All our offered products are fabricated as per the set quality norms and
        thus ensure accurate performance. We are offering these products at
        leading market prices within the requested frame of time as per the
        client’s detailed requirements.
        <br />
        <br />
        <b className="color-blue">
          Some more factors which makes us favorable are:
        </b>
      </p>
      <ul data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
        <li>Wide array of product</li>
        <li>On time delivery</li>
        <li>Quality assurance</li>
        <li>Easy payment options</li>
        <li>Highly advanced infrastructure</li>
        <li>Well qualified workforce</li>
      </ul>
    </div>
  );
}
