import React from "react";

export default function reviewCard(props) {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < props.star; i++) {
      stars.push(<i className="fa-solid fa-star"></i>);
    }
    for (let i = props.star; i < 5; i++) {
      stars.push(<i className="fa-regular fa-star"></i>);
    }
    return stars;
  };

  return (
    <div>
      <div
        className="card mb-4 shadow-lg review-card-body text-white"
        data-aos="slide-right"
        data-aos-delay="200"
        data-aos-once="true"
      >
        <div
          className="card-header rating-stars-testimonial"
          data-aos="fade-left"
          data-aos-delay="300"
          data-aos-once="true"
        >
          {renderStars()}
        </div>
        <div className="card-body">
          <h3
            className="card-title"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
          >
            {props.Username}
          </h3>
          <p>{props.UserLocation}</p>
          <div className="row">
            <div className="col-md-1">
              <p>
                <i className="fa-solid fa-quote-left review-quote"></i>
              </p>
            </div>
            <div className="col-md-10">
              <p
                className="card-text"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-once="true"
              >
                {props.UserReview}
              </p>
            </div>
            <div className="col-md-1">
              <p>
                <i className="fa-solid fa-quote-right review-quote mt-5 pt-2"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

reviewCard.defaultProps = {
  star: 0,
  Username: "Anonymous",
  UserLocation: "Somewhere in the World",
  UserReview: `Lorem ipsum dolor sit amet consectetur adipis`,
};
