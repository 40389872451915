import React from "react";
import ReviewCard from "./reviewCard";
import backgroundImage from "../../../Images/bg-curve-line.png";
import backgroundImage2 from "../../../Images/bg-obj.png";

export default function testimonial() {
  return (
    <div className="pb-5 container-fluid">
      <div className="row">
        <div className="col-md-9">
          <ReviewCard
            star={5}
            Username="Mohit Yadav"
            UserReview="Impeccable quality and outstanding service characterize our experience with PH Steels Pvt Ltd. Their steel products excel in both durability and performance, meeting our project needs with precision. From expert guidance in product selection to prompt delivery, their professionalism shines through. With PH Steels, we've found a reliable partner for all our steel requirements. Highly recommended for their excellence."
            UserLocation="Bhiwadi, Rajasthan"
          />
        </div>
        <div
          className="col-md-3 rotating-bg mt-5"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <ReviewCard
            star={4}
            Username="Abhishek Singh"
            UserReview="Choosing PH Steels was the best decision for our steel needs. Their products are of superior quality, ensuring our projects are built to last. The team's dedication to customer satisfaction is evident in their seamless service and prompt delivery. With their expertise and reliability, PH Steels has become our go-to for top-notch steel solutions."
            UserLocation="New Delhi, Delhi"
          />
        </div>
        <div className="col-md-1"></div>
      </div>
      <div className="row">
        <div className="col-md-1"></div>
        <div
          className="col-md-2 mt-5"
          style={{
            backgroundImage: `url(${backgroundImage2})`,
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="col-md-9">
          <ReviewCard
            star={4}
            Username="Jitender Singh"
            UserReview="Exemplary service and top-tier quality define PH Steels. We've been consistently impressed by their steel products, which meet the highest standards of durability and performance. From start to finish, their team ensures a smooth process, from product selection to delivery. With PH Steels, we've found a trusted partner for all our steel requirements, making our projects shine."
            UserLocation="Mumbai, Maharastra"
          />
        </div>
      </div>
    </div>
  );
}
