import React from "react";
import Banner from "./Banner";
import AuthDis from "./AuthorisedDistributor/main";
import WelcomePage from "./WelcomePage";
import ExploreWork from "./exploreWork";
import Rating from "./reviews/rating";
import OurClients from "../front-page/ourClients";

export default function main() {
  return (
    <div>
      <Banner />
      <WelcomePage />
      <AuthDis />
      <ExploreWork />
      <Rating />
      <OurClients />
    </div>
  );
}
