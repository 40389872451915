import React from "react";

export default function factsheet() {
  return (
    <div>
      <div className="container pb-5">
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Nature of Business</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">Exporter and Manufacturer</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Additional Business</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">
              Wholesaler, Trader, Importer, Supplier
            </p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Company CEO</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">Vikas Goel</p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Registered Address</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">
              102, Loha Mandi , Bulandshahr Road Industrial Area, Ghaziabad-
              201009, Uttar Pradesh, India
            </p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Total Number of Employees</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">11 to 25 People</p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Year of Establishment</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">2011</p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Legal Status of Firm</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">Partnership Firm</p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Annual Turnover</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">Rs. 50 - 100 Crore</p>
          </div>
        </div>
        <hr />

        <div
          className="row mt-5 pb-5"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-12">
            <h2>Company USP</h2>
          </div>
        </div>

        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Primary Competitive Advantage</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">
              Large Product Line, Good Financial Position & TQM
            </p>
          </div>
        </div>
        <hr />

        <div
          className="row mt-5 pb-5"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-12">
            <h2>Statutory Profile</h2>
          </div>
        </div>

        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Import Export Code (IEC)</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">05150*****</p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Tan No.</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">MRTP0*****</p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Banker</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">
              ING VYSYA BANK LTD, Axis Bank, , Kotak Mahindra Bank
            </p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>GST No.</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">09AANFP1538M1ZO</p>
          </div>
        </div>
        <hr />

        <div
          className="row mt-5 pb-5"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-12">
            <h2>Statutory Profile</h2>
          </div>
        </div>

        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Payment Mode</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">Cash, Cheque, DD</p>
          </div>
        </div>
        <hr />
        <div
          className="row"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once="true"
        >
          <div className="col-md-6 d-flex justify-content-center">
            <h3>Shipment Mode</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <p className="about-us-factsheet">By Road</p>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
}
