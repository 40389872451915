import React from "react";
import ProductShowCase from "./ProductShowCase/ProductShowCase";

export default function exploreWork() {
  return (
    <div>
      <div
        className="container-fluid exploreWorkDiv pt-5 pb-3"
        data-aos="slide-right"
        data-aos-delay="300"
        data-aos-once="true"
      >
        <div className="container">
          <div className="row">
            <h3
              className="text-center text-white"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-once="true"
            >
              ━━━ Explore Our Products ━━━
            </h3>
            <h1
              className="text-center text-white"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-once="true"
            >
              Check out our world class range of Products.
            </h1>
          </div>
        </div>
        <ProductShowCase />
      </div>
    </div>
  );
}
