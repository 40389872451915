import React from "react";
import YouTubeVideo from "./YoutubeVideo";

export default function VideoPage() {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="mb-2 mt-5 text-uppercase color-blue">
              Corporate Video
            </h1>
            <p className="corporate-video-p">
              Discover excellence in motion. Watch our story unfold.
            </p>
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center pb-5">
            <YouTubeVideo video_id="OBlrM_x9pEY" />
          </div>
          <div className="col-md-12 d-flex justify-content-center pb-5">
            <YouTubeVideo video_id="wVu134n3LHg" />
          </div>
        </div>
      </div>
    </>
  );
}
